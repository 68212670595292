import React, { useState, useEffect, useRef } from 'react';
import SearchIt from './SearchIt.jsx';
import ProductsScrollView from './ProductsScrollView.jsx';
import Loader from './Loader.jsx';
import styles from './css/MainContainer.module.css';
import bgVedio from '../assets/bg.mp4';
import { BASE_URL, getSessionId} from '../lib/common.js';
import vidaGif from '../assets/vida.gif';
import {getUserId, getMsgSeqId} from '../lib/logger';
import { uxStoreName } from './local';
import { useLocation } from 'react-router-dom';

const MainContainer = () => {
    const { search } = useLocation();
    const queryParams = new URLSearchParams(search);
    const storeName = queryParams.get('storeName') || 'aistylist';

    const [query, setQuery] = useState('');
    const [pageNo, setPageNo] = useState(1);
    const [hasMorePages, setHasMorePages] = useState(true);
    const [loading, setLoading] = useState(false);

    const scrollContainerTopRef = useRef(null);
    const scrollContainerBottomRef = useRef(null);

    const [topProducts, setTopProducts] = useState(null);
    const [bottomProducts, setBottomProducts] = useState(null);
    const [contextWindow, setContextWindow] = useState({});
    const [filters, setFilters] = useState({});
    const [selectedFilters, setSelectedFilters] = useState([]);

    const [firstTimeFocused, setFirstTimeFocused] = useState(true);

    const removeProducts = async () => {
        setTopProducts([]);
        setBottomProducts([]);
    }

    const fetchProducts = async (userQuery, pageNo) => {
        const user_id = await getUserId(uxStoreName);
        const sbv_sid = getSessionId();
        const MsgSeqId = getMsgSeqId(uxStoreName);

        setLoading(true);
        // const url = `http://64.227.152.133:5000/sbv/ml/imageSearch?store_name=${storeName}&user_name=Stylist&userPrompt=${userQuery}&page_no=${pageNo}&devName=shiv`;
        const url = `${BASE_URL}/sbv/aistylist/searchQuery?storeName=${storeName}&user_name=Stylist&userPrompt=${userQuery}&page_no=${pageNo}&user_id=${user_id}&sbv_sid=${sbv_sid}&MsgSeqId=${MsgSeqId}&is_dual=true&client_type=promptbased&devName=shiv`;

        if(pageNo == 1) await removeProducts();

        await fetch(url, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then((response) => response.json())
            .then((data) => {
                setHasMorePages(data.productList.topList.length >= 20 ? true : false);
                setLoading(false);

                if (pageNo == 1) setTopProducts(data.productList.topList);
                else setTopProducts([...topProducts, ...data.productList.topList]);

                if (pageNo == 1) setBottomProducts(data.productList.bottomList);
                else setBottomProducts([...bottomProducts, ...data.productList.bottomList]);

                setContextWindow(data.contextWindow);
                if (data.filters && data.filters.filter) {
                    let filters = data.filters.filter;
                    setFilters(filters);
                }
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
                setLoading(false);
            });
    };

    const fetchProductsAfterCrossFilter = async (filterName) => {
        const user_id = await getUserId(uxStoreName);
        const sbv_sid = getSessionId();
        const MsgSeqId = getMsgSeqId(uxStoreName);
        setLoading(true);
        const url = `${BASE_URL}/sbv/aistylist/crossFilter?storeName=${storeName}&user_name=Stylist&filterName=${filterName}&user_id=${user_id}&sbv_sid=${sbv_sid}&MsgSeqId=${MsgSeqId}&is_dual=true&client_type=promptbased&devName=shiv`;

        await removeProducts();

        await fetch(url, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then((response) => response.json())
            .then((data) => {
                setHasMorePages(data.productList.topList.length >= 20 ? true : false);
                setLoading(false);

                setTopProducts(data.productList.topList);
                setBottomProducts(data.productList.bottomList);


                setContextWindow(data.contextWindow);
                if (data.filters && data.filters.filter) {
                    let filters = data.filters.filter;

                    // const filterList = Object.keys(filters).reduce((acc, key) => {
                    //     const values = filters[key].map(item => item);
                    //     return [...acc, ...values];
                    // }, []);
                    setFilters(filters);
                }
                if (scrollContainerTopRef.current) {
                    const tempScrollLeft = scrollContainerTopRef.current.scrollLeft;
                    setTimeout(() => {
                        scrollContainerTopRef.current.scrollTo({
                            left: 0,
                            behavior: 'auto',
                        });
                    }, 0);
                }
                if (scrollContainerBottomRef.current) {
                    const tempScrollLeft = scrollContainerBottomRef.current.scrollLeft;
                    setTimeout(() => {
                        scrollContainerBottomRef.current.scrollTo({
                            left: 0,
                            behavior: 'auto',
                        });
                    }, 0);
                }
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
                setLoading(false);
            });
    };

    const fetchProductsAfterAddFilter = async (filterType, filterName) => {
        const user_id = await getUserId(uxStoreName);
        const sbv_sid = getSessionId();
        const MsgSeqId = getMsgSeqId(uxStoreName);
        setLoading(true);
        const url = `${BASE_URL}/sbv/aistylist/addFilter?storeName=${storeName}&user_name=Stylist&filterType=${filterType}&filterName=${filterName}&user_id=${user_id}&sbv_sid=${sbv_sid}&MsgSeqId=${MsgSeqId}&is_dual=true&client_type=promptbased&devName=shiv`;

        await removeProducts();

        await fetch(url, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then((response) => response.json())
            .then((data) => {
                setHasMorePages(data.productList.topList.length >= 20 ? true : false);
                setLoading(false);

                setTopProducts(data.productList.topList);
                setBottomProducts(data.productList.bottomList);

                setContextWindow(data.contextWindow);
                if (data.filters && data.filters.filter) {
                    let filters = data.filters.filter;

                    // const filterList = Object.keys(filters).reduce((acc, key) => {
                    //     const values = filters[key].map(item => item);
                    //     return [...acc, ...values];
                    // }, []);

                    setFilters(filters);
                }

                if (scrollContainerTopRef.current) {
                    const tempScrollLeft = scrollContainerTopRef.current.scrollLeft;
                    setTimeout(() => {
                        scrollContainerTopRef.current.scrollTo({
                            left: 0,
                            behavior: 'auto',
                        });
                    }, 0);
                }
                if (scrollContainerBottomRef.current) {
                    const tempScrollLeft = scrollContainerBottomRef.current.scrollLeft;
                    setTimeout(() => {
                        scrollContainerBottomRef.current.scrollTo({
                            left: 0,
                            behavior: 'auto',
                        });
                    }, 0);
                }
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
                setLoading(false);
            });
    };

    useEffect(() => {
        setPageNo(1);
        setHasMorePages(true);
        if (query && query.trim() !== "") {
            fetchProducts(query, 1);
            if (scrollContainerTopRef.current) {
                const tempScrollLeft = scrollContainerTopRef.current.scrollLeft;
                setTimeout(() => {
                    scrollContainerTopRef.current.scrollTo({
                        left: 0,
                        behavior: 'auto',
                    });
                }, 0);
            }
            if (scrollContainerBottomRef.current) {
                const tempScrollLeft = scrollContainerBottomRef.current.scrollLeft;
                setTimeout(() => {
                    scrollContainerBottomRef.current.scrollTo({
                        left: 0,
                        behavior: 'auto',
                    });
                }, 0);
            }
        }
    }, [query])

    useEffect(() => {
        console.log("pageNo", pageNo);
        if (query && query.trim() !== "") {
            fetchProducts(query, pageNo);
        }
    }, [pageNo])

    useEffect(() => {
        if (contextWindow) {
            const filterList = Object.keys(contextWindow).reduce((acc, key) => {
                if (!key.startsWith("__") && !key.startsWith("state")) {
                    const values = contextWindow[key].map(item => item.key);
                    return [...acc, ...values];
                }
                return acc;
            }, []);
            setSelectedFilters(filterList);
        }
    }, [contextWindow]);

    return (
        <div className={styles['mainCont']}>
            <SearchIt setQuery={setQuery} firstTimeFocused={firstTimeFocused} setFirstTimeFocused={setFirstTimeFocused} selectedFilters={selectedFilters} filters={filters} fetchProductsAfterCrossFilter={fetchProductsAfterCrossFilter} fetchProductsAfterAddFilter={fetchProductsAfterAddFilter} />
            {/* <div style={{ display: "flex", flexDirection:"column", justifyContent: "center", alignItems: "center", height: "89vh" }}> */}
            {/* <div style={{ display: "flex", flexDirection:"column", justifyContent: "center", alignItems: "center", position: "relative", height: "91vh", top: "30px"}}> */}
            {/* <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", position: "relative", height: "85vh", top: "30px" }}> */}
            {
                !firstTimeFocused &&
                // <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", position: "relative", height: "85vh", top: "15px" }}>
                // <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", position: "relative", height:"85%", border:"2px solid yellow", top:"10%"}}>
                // <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", position: "fixed",left:"0", right:"0", height:"85vh",border:"2px solid yellow", top:"65px"}}>
                // <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center",border:"2px solid yellow", marginTop:"20%", position:"fixed", height:"88%", left:"0", right:"0"}}>
                <div className={styles["scrollCont"]}>
                    {(loading && (topProducts == null)) && <Loader />}
                    <ProductsScrollView storeName={storeName} loading={loading} products={topProducts} setPageNo={setPageNo} hasMorePages={hasMorePages} contextWindow={contextWindow} fetchProductsAfterAddFilter={fetchProductsAfterAddFilter} firstTimeFocused={firstTimeFocused} scrollContainerRef={scrollContainerTopRef} />
                    <ProductsScrollView storeName={storeName} loading={loading} products={bottomProducts} setPageNo={setPageNo} hasMorePages={hasMorePages} contextWindow={contextWindow} fetchProductsAfterAddFilter={fetchProductsAfterAddFilter} firstTimeFocused={firstTimeFocused} scrollContainerRef={scrollContainerBottomRef} />
                </div>
            }

            {
                firstTimeFocused &&
                // (<div style={{ width: "auto", height: "80vh", mixBlendMode: "lighten", position: "absolute", top: "0" }}>
                // (<div style={{ width: "100%", height: "75vh", mixBlendMode: "lighten", position: "absolute", top: "30%", textAlign:"center" }}>
                (<div className={styles["vidaGifCont"]}>
                    <img className={styles["vidaGif"]} src={vidaGif} alt="gif" />
                    {/* <img className="vidaGif" src={initialGif} alt="gif" /> */}
                    {/* <img className="vidaGif" src={initialGif2} alt="gif" /> */}
                </div>)
            }
        </div>
    )
}

export default MainContainer;