import React, { useState, useEffect } from 'react';
import styles from './css/ProductsScrollView.module.css';
import ProductCard from './ProductCard';
import ProductDetailCard from './ProductDetailCard';
import closeIcon from '../assets/close.svg';
import Loader from './Loader';
import QuestionCard from './QuestionCard';
import vidaGif from '../assets/vida.gif';

const ProductsScrollView = ({ storeName, products, setPageNo, hasMorePages, loading, contextWindow, fetchProductsAfterAddFilter, firstTimeFocused, scrollContainerRef }) => {
    const [selectedProduct, setSelectedProduct] = useState(null);
    // const [modifiedProducts, setModifiedProducts] = useState([]);

    // useEffect(() => {
    //     const pList = [...products];

    //     if(contextWindow && contextWindow.body_pref && contextWindow.body_pref.length === 0)
    //     {
    //         const ri = Math.floor(Math.random() * (pList.length + 1));
    //         if (pList.length > 0) {
    //             pList.splice(ri, 0, { type: 'default' });
    //         }
    //     }

    //     setModifiedProducts(pList);

    // }, [products]);

    const handleProductClick = (index) => {
        const clickedProduct = products[index];
        setSelectedProduct(clickedProduct);
    };

    const handleCloseModal = () => {
        setSelectedProduct(null);
    };

    const handleScroll = (e) => {
        const { scrollWidth, scrollLeft, clientWidth } = e.target;
        // console.log(scrollWidth, scrollLeft, scrollWidth - scrollLeft, clientWidth);
        // console.log(Math.round(scrollWidth - scrollLeft), clientWidth - 2, clientWidth + 2, hasMorePages);
        // if (Math.round(scrollWidth - scrollLeft) >= clientWidth - 2 && Math.round(scrollWidth - scrollLeft) <= clientWidth + 2) {
        if (Math.round(scrollWidth - scrollLeft) >= clientWidth - 2 && Math.round(scrollWidth - scrollLeft) <= clientWidth + 2) {
            if (hasMorePages && !loading) setPageNo((pageNO) => (pageNO + 1));
        }
    };

    return (
        <>
            {
                !firstTimeFocused && products != null && <>
                    <div div className={`${styles.productsScrollView} ${styles['snaps-inline']}`} onScroll={handleScroll} ref={scrollContainerRef} >
                        {
                            products.map((product, index) => (
                                (product.type === 'default') ? (
                                    <QuestionCard key={index} queObj={product} fetchProductsAfterAddFilter={fetchProductsAfterAddFilter} />
                                ) : (
                                    <ProductCard key={product.product_handle} {...product} storeName={storeName} onClick={() => handleProductClick(index)} />
                                )
                            ))
                        }
                        {loading && products.length > 0 && <Loader />}
                    </div >
                    {/* {loading && (products.length === 0) && <Loader />} */}
                    {
                        selectedProduct && (
                            <>
                                <div className={styles["modalOverlay"]}>
                                    <div className={styles["mod"]}>
                                        <span onClick={handleCloseModal}>
                                            <img className={styles["closeIcon"]} src={closeIcon} alt="close" />
                                        </span>
                                        <ProductDetailCard storeName={storeName} product_handle={selectedProduct.product_handle} />
                                    </div>
                                </div>
                            </>
                        )
                    }
                </>
            }
        </>
    )
}

export default ProductsScrollView;