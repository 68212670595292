//Function to send application logs to server and also log them in the console

import { BASE_URL, getCurrDateTime, getSessionId } from "./common";
import { requestHandlerFunction } from "./RequestHandler";

const logger = (log, filename = "") => {
  let log_line = "";
  if (typeof log === "object") {
    log_line = JSON.stringify(log);
  } else {
    log_line = log;
  }

  let ts = getCurrDateTime();
  console.log(filename + " : " + log_line + " - " + ts);
  // log_line = ts + "::" + filename + " : " + log_line;
  // const url = BASE_URL + "client-log?log_line=" + log_line + "&devname=mk2";
  // axios.get(url).catch(function (error) {
  //   console.log("Error: Cannot send client log to server");
  //   console.log(error);
  // });
};

// function generateRandomNumber(max) {
//   let randomNumber = Math.floor(Math.random() * max) + 1;
//   return randomNumber.toString();
// }

// const getSessionId = (store_name) => {
//   let sessionId = null;
//   if (sessionStorage.getItem(`SBV_SessionID_${store_name}`) === null) {
//     let dateTime = getCurrDateTime();
//     let randomNumber = generateRandomNumber(1000);

//     sessionId = dateTime + "-" + randomNumber;
//     sessionStorage.setItem(`SBV_SessionID_${store_name}`, sessionId);
//     console.log("Setting SessionId:", sessionId);
//   } else {
//     let retrievedSessionId = sessionStorage.getItem(`SBV_SessionID_${store_name}`);
//     sessionId = retrievedSessionId;
//     console.log("RetrievedSessionId:", retrievedSessionId);
//   }
//   return sessionId;
// };

export const getUserId = async (store_name) => {
  let userId = localStorage.getItem(`SBV_UserID_${store_name}`);
  if (userId === null || userId === "ux" || userId === "uy" || userId === "uz") {
    try {
      const url = BASE_URL + "/getNextUserId";
      const myData = { store_name: store_name };
      const resp = await requestHandlerFunction(url, "get_next_userId", "get", "json", myData);
      userId = resp[0].data.data;
      console.log("userId:", userId);
      localStorage.setItem(`SBV_UserID_${store_name}`, userId);
      console.log("Setting userId:", userId);
    } catch (error) {
      console.error(error);
    }
  } else {
    console.log("RetrievedUserId:", userId);
  }
  return userId;
};

const getAndSetMsgSeqId = (store_name) => {
  let MsgSeqId = sessionStorage.getItem(`SBV_MsgSeqID_${store_name}`);
  if (MsgSeqId === null) {
      MsgSeqId = 1;
      sessionStorage.setItem(`SBV_MsgSeqID_${store_name}`, MsgSeqId);
      console.log("Setting MsgSeqId:", MsgSeqId);
    } else {
      console.log("RetrievedMsgSeqId:", MsgSeqId);
      MsgSeqId++;
      sessionStorage.setItem(`SBV_MsgSeqID_${store_name}`, MsgSeqId);
      console.log("Setting MsgSeqId:", MsgSeqId);
  }
  return MsgSeqId;
};

export const getMsgSeqId = (store_name) => {
  let MsgSeqId = sessionStorage.getItem(`SBV_MsgSeqID_${store_name}`);
  if (MsgSeqId === null) {
    MsgSeqId = 0;
    console.log("Returning MsgSeqId:", MsgSeqId);
  } else {
    console.log("Returning MsgSeqId:", MsgSeqId);
  }
  return MsgSeqId;
};

export const UXLog = async (store_name, uxItem, artifactName = "NA", ux_value = "NA",
  miscInfo = "NA") => {
  var dateTime = getCurrDateTime();
  //   let sessionId = getSessionId(store_name);
  let sessionId = getSessionId();
  let userId = await getUserId(store_name);
  let MsgSeqId = getAndSetMsgSeqId(store_name);

  console.log(
    "UXLog:: Store_name: " + store_name + "\n" +
    "User_id: " + userId + "\n" +
    "Session_id: " + sessionId + "\n" +
    "MsgSeqId: " + MsgSeqId + "\n" +
    "UX-Item: " + JSON.stringify(uxItem) + "\n" +
    "TimeStamp: " + dateTime + "\n" +
    "ArtifactName: " +JSON.stringify(artifactName) + "\n" +
    "UX-Value: " + JSON.stringify(ux_value) + "\n" +
    "MiscInfo: " + JSON.stringify(miscInfo) + "\n"
  );

  const myData = {
    store_name: store_name,
    user_id: userId,
    session_id: sessionId,
    MsgSeqId: MsgSeqId,
    uxTimeStamp: dateTime, 
    uxItem: uxItem,
    artifactName: artifactName,
    uxValue: ux_value,
    miscInfo: miscInfo,
    devname: "AISTYLIST",
  };

  console.log("UXLog: ", myData);
  const url = BASE_URL + "/ux-log";
  try {
    await requestHandlerFunction(url, "ux-log", "post", "arraybuffer", myData);
  } catch (error) {
    console.error(error);
  }
};

export default logger;
