import React, { useState } from 'react';
import styles from './css/QuestionCard.module.css';

const QuestionCard = ({ queObj, fetchProductsAfterAddFilter }) => {
    console.log(queObj.question);
    return (
        <div className={styles['questionCardCont']}>
            <div>
                {/* <h2 className={styles["question"]}>{queObj.question}</h2> */}
                <h2 className={styles["question"]}>{queObj.question.split("?")[0]}? <br /> {queObj.question.split("?")[1]}</h2>
            </div>
            <div className={styles['optionCont']}>
                {
                    queObj.options.map((option, idx) => (
                        <div className={styles['option-item']}key={option} onClick={() => { fetchProductsAfterAddFilter(queObj, queObj.userPref, queObj.keys[idx]) }} >{option}</div>
                    ))
                }
            </div>
        </div>
    )
}

export default QuestionCard;