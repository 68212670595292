import React, { useState, useEffect, useRef } from 'react';
import styles from './css/SearchIt.module.css';
import searchIcon from "../assets/search-icon.svg";
import logo from "../assets/vidaLogo.png";
import closeIcon from '../assets/close.svg';
import helpJsonData from '../data/help.json';

const SearchIt = ({ setQuery, firstTimeFocused, setFirstTimeFocused, selectedFilters, filters, fetchProductsAfterCrossFilter, fetchProductsAfterAddFilter, setProducts }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const [selectedSuggestionIndex, setSelectedSuggestionIndex] = useState(-1);

    const placeholderString = "Shop with AI Stylist. Find your Look...";
    const [placeholder, setPlaceholder] = useState(placeholderString.slice(0, 0));
    const [placeholderIndex, setPlaceholderIndex] = useState(0);
    const [isSearchBarFocused, setIsSearchBarFocused] = useState(false);

    const handleSearchTerm = (e) => {
        if (firstTimeFocused) {
            setFirstTimeFocused(false);
        }
        setIsSearchBarFocused(true);
        const value = e.target.value;
        setSearchTerm(value);
        fetchSuggestions(value);
    };

    const handleSearch = () => {
        setIsSearchBarFocused(false);
        setQuery(searchTerm);
        setSuggestions([]);
    };

    const handleSelectSuggestion = (suggestion) => {
        setSearchTerm(suggestion);
        setQuery(suggestion);
        setSuggestions([]);
    };

    const handleKeyDown = (e) => {
        if (e.key === 'ArrowUp' && selectedSuggestionIndex > 0) {
            setSelectedSuggestionIndex(selectedSuggestionIndex - 1);
            setSearchTerm(suggestions[selectedSuggestionIndex - 1]);
        } else if (e.key === 'ArrowDown' && selectedSuggestionIndex < suggestions.length - 1) {
            setSelectedSuggestionIndex(selectedSuggestionIndex + 1);
            setSearchTerm(suggestions[selectedSuggestionIndex + 1]);
        } else if (e.key === 'Enter') {
            if (selectedSuggestionIndex !== -1) {
                handleSelectSuggestion(suggestions[selectedSuggestionIndex]);
                setSearchTerm(suggestions[selectedSuggestionIndex]);
            } else {
                handleSearch();
            }
        }
    };

    const fetchSuggestions = async (searchTerm) => {
        const newSuggestions = [];
        if (searchTerm.trim() !== "") {
            Object.keys(helpJsonData).forEach(key => {
                helpJsonData[key].map((suggestion) => {
                    if (suggestion.toLowerCase().includes(searchTerm.toLowerCase())) {
                        newSuggestions.push(suggestion);
                    }
                })
            });
        }
        else {
            Object.keys(helpJsonData).forEach(key => {
                const randomIndex = Math.floor(Math.random() * helpJsonData[key].length);
                const randomString = helpJsonData[key][randomIndex];
                newSuggestions.push(randomString);
            });
        }

        setSuggestions(newSuggestions);
    };

    useEffect(() => {
        const intr = setInterval(() => {
            setPlaceholder(placeholderString.slice(0, placeholderIndex));
            if (placeholderIndex + 1 > placeholderString.length) {
                setPlaceholderIndex(0)
            } else {
                setPlaceholderIndex(placeholderIndex + 1)
            }
        }, 150);
        return () => {
            clearInterval(intr)
        }
    },);

    useEffect(() => {
        const interval = setInterval(() => {
            if (isSearchBarFocused) {
                setPlaceholder("");
                clearInterval(interval);
            } else {
                setPlaceholder(placeholderString.slice(0, placeholderIndex));

                if (placeholderIndex + 1 > placeholderString.length) {
                    setPlaceholderIndex(0);
                } else {
                    setPlaceholderIndex(placeholderIndex + 1);
                }
            }
        }, 150);

        return () => {
            clearInterval(interval);
        };
    }, [isSearchBarFocused, placeholderIndex, placeholderString]);

    const crossFilter = (filter) => {
        fetchProductsAfterCrossFilter(filter);
    };
    const addFilter = (filterType, filter) => {
        fetchProductsAfterAddFilter(filterType, filter);
    };

    return (
        // <div className={firstTimeFocused ? 'preSearchCont' : 'searchCont'}>
        // <div className={`searchCont ${firstTimeFocused ? 'preSearchCont' : ''}`}>
        <div className={`${styles.searchCont} ${firstTimeFocused ? styles['preSC'] : ''}`}>
            <div className={styles["logo"]}>
                <img src={logo} alt="logo" draggable={false} />
            </div>
            <div className={styles['searchBox']}>
                {/* <div className="searchIcon">
                    <img src={searchIcon} alt="searchIcon" draggable={false} />
                </div> */}
                <input
                    type="text"
                    placeholder={placeholder}
                    className={styles["searchInput"]}
                    value={searchTerm}
                    onChange={handleSearchTerm}
                    onFocus={handleSearchTerm}
                    onBlur={handleSearch}
                    // onKeyDown={(e) => {
                    //     if (e.key === 'Enter') {
                    //         handleSearch();
                    //     }
                    // }}
                    onKeyDown={handleKeyDown}
                />

                <div className={styles['filterCont']}>
                    <div className={styles['selectedFilterCont']}>
                        {selectedFilters.length > 0 && (
                            selectedFilters.map((filter, index) => (
                                <div key={filter} className={styles['selectedFilterDiv']}>
                                    <span style={{ whiteSpace: "nowrap" }}>{filter}</span>
                                    <span onClick={() => { crossFilter(filter); }}>
                                        <img className={styles["closeIcon"]} src={closeIcon} alt="close" />
                                    </span>
                                </div>
                            ))
                        )}
                    </div>

                    <div className={styles['unSelectedFilterCont']}>
                        {Object.keys(filters).map((filterType) => (
                            filters[filterType].length > 0 && (
                                filters[filterType].map((filter, index) => (
                                    <div key={filter} className={styles['filterDiv']} onClick={() => { addFilter(filterType, filter); }}>
                                        <span style={{ whiteSpace: "nowrap" }}>{filter}</span>
                                        {/* <span onClick={() => { addFilter(filterType, filter); }}>
                                            <img className="closeIcon" src={closeIcon} alt="close" />
                                        </span>  */}
                                    </div>
                                ))
                            )
                        ))}
                    </div>
                </div>


                {suggestions.length > 0 && (
                    <ul className={styles["suggestionList"]}>
                        {suggestions.slice(0, 3).map((suggestion, index) => (
                            <li className={index === selectedSuggestionIndex ? styles['selected'] : ''} key={suggestion} onMouseDown={() => handleSelectSuggestion(suggestion)} >
                                {suggestion}
                            </li>
                        ))}
                    </ul>
                )}
            </div>
        </div>
    )
}

export default SearchIt;