import React, { useState } from 'react';
import styles from './css/ProductCard.module.css';
import { BASE_URL } from '../lib/common';
import Tooltip from './Tooltip';
import Skeleton from '@mui/material/Skeleton';
import Box from '@mui/material/Box';

// const ProductCard = ({ id, title, imageSrc, price, description, onClick}) => {
const ProductCard = ({ storeName, product_handle, title, imgUrl, body_pref_tags, vendor_name, description, price, onClick }) => {

    const [imageLoaded, setImageLoaded] = useState(false);
    const brandLogos = {
        'fablestreet': "https://fablestreet.com/favicon.ico",
        'thelabellife': "https://labellifeassets.tectonic.so/images/original/cce0315262d7f7341c7bcef3a0f2623a.png",
        'cavaathleisure': "https://cavaathleisure.com/cdn/shop/files/logo-01_32x32.png?v=1710322846",
        'prathaa-weavingtraditions': "https://prathaa.in/cdn/shop/files/logo_231x.png?v=1613670071",
        'prathaaweavingtraditions': "https://prathaa.in/cdn/shop/files/logo_231x.png?v=1613670071"
    };
    const bLogo = brandLogos[vendor_name];

    const { positive_tags, negative_tags } = body_pref_tags;

    const truncateTitle = (title, maxChars) => {
        if (title.length <= maxChars) {
            return title;
        } else {
            const truncatedTitle = title.substring(0, maxChars);
            const lastSpaceIndex = truncatedTitle.lastIndexOf(' ');

            if (truncatedTitle[lastSpaceIndex - 1] === '-') {
                return truncatedTitle.substring(0, lastSpaceIndex - 1);
            }

            return truncatedTitle.substring(0, lastSpaceIndex);
        }
    };

    return (
        <div className={styles.productCardCont} onClick={onClick}>
            <Box>
                {/* <span className='priceCont'>₹{price}</span> */}
                {/* <img src={imageSrc} alt="productImg" draggable={false}/> */}
                {/* <span className={styles.priceCont}>₹{99}</span> */}
                {!imageLoaded && (
                    <Skeleton variant="rounded"
                        sx={{
                            width: '100%',
                            height: 'auto',
                            aspectRatio: '3 / 4', // This sets the aspect ratio
                        }}
                    />
                )}
                <img src={`${BASE_URL}/getProdImage?store_name=${storeName}&dress_id=${product_handle}&image_name=${imgUrl}`} alt="productImg" draggable={false} onLoad={() => setImageLoaded(true)} style={{ display: imageLoaded ? 'block' : 'none', width: '100%' }} />
            </Box>
            <div className={styles['product-details']}>
                {/* <h2 className="product-title">{truncateTitle(title, 30)}</h2> */}
                {/* <div className={styles['product-title']}>

                    {bLogo && (
                        <div>
                            <Tooltip text={vendor_name}>
                                <img src={bLogo} alt="" style={{ width: "15px", height: "15px", marginRight: "5px" }} />
                            </Tooltip>
                        </div>
                    )
                    }

                    {positive_tags && positive_tags.length > 0 ? (
                        positive_tags.map((tag, index) => (
                            // <span key={index} style={{ backgroundColor:"#00800026" }} className='nudge'>
                            <span key={index} style={{ border: "1px solid green" }} className={styles['nudge']}>
                                {tag}
                            </span>
                        ))
                    ) : null}

                    {negative_tags && negative_tags.length > 0 ? (
                        negative_tags.map((tag, index) => (
                            // <span key={index} style={{ backgroundColor:"#ff000026" }} className='nudge'>
                            <span key={index} style={{ border: "1px solid red" }} className={styles['nudge']}>
                                {tag}
                            </span>
                        ))
                    ) : null}

                    {((positive_tags && negative_tags && positive_tags.length === 0 && negative_tags.length === 0) || body_pref_tags.length === 0) && (
                        <span className={styles['nudge']}>
                            {truncateTitle(title, 30)}
                        </span>
                    )}

                </div> */}

                <div className={styles['product-title-cont']}>

                    <div className={styles['product-title']}>
                        {bLogo && (
                            <Tooltip text={vendor_name}>
                                <img src={bLogo} alt="" style={{ width: "15px", height: "15px", marginRight: "5px" }} />
                            </Tooltip>
                        )}
                        <div className={styles['nudge']}>
                            {truncateTitle(title, 30)}
                        </div>
                    </div>
                    {description &&
                        <>
                            <div className={styles['nudge']}>
                                {description}
                            </div>
                        </>
                    }
                    {price &&
                        // <div className={styles['priceCont']}>
                        <div className={styles['price-div']}>
                            Rs.&nbsp;{price}
                        </div>
                    }


                    <div className={styles['aitag']}>
                        {positive_tags && positive_tags.length > 0 ? (
                            <>
                                <span>Do's:&nbsp;</span>
                                {positive_tags.map((tag, index) => (
                                    // <span key={index} style={{ backgroundColor: "#00800026" }} className='nudge'>
                                    <span key={index} style={{ border: "1px solid green" }} className={styles['nudge']}>
                                        {tag}
                                    </span>
                                ))}
                            </>
                        ) : null}
                    </div>

                    {storeName != "prathaa" &&
                        <div className={styles['aitag']}>
                            {negative_tags && negative_tags.length > 0 ? (
                                <>
                                    <span>Don'ts:&nbsp;</span>
                                    {negative_tags.map((tag, index) => (
                                        // <span key={index} style={{ backgroundColor:"#ff000026" }} className='nudge'>
                                        <span key={index} style={{ border: "1px solid red" }} className={styles['nudge']}>
                                            {tag}
                                        </span>
                                    ))}
                                </>
                            ) : null}
                        </div>
                    }
                </div>
            </div>
        </div >
    )
}

export default ProductCard;