import React, { useState } from 'react';
import './css/SizeChart.css';
import closeIcon from '../assets/close.svg';
import prathaa_sc from '../assets/prathaa_size_chart.png';


const SizeChart = ({ msg }) => {
    const [isOpen, setIsOpen] = useState(false);

    const handleCloseModal = () => {
        setIsOpen(false);
    };

    const handleOpenModal = () => {
        setIsOpen(true);
    };

    return (
        <div className='sizechartCont'>
            {
                isOpen ? (
                    <>
                        <div className="modalOverlay">
                            <div className="mod">
                                <span onClick={handleCloseModal}>
                                    <img className={"closeIcon"} src={closeIcon} alt="close" />
                                    <img className="sizechartImg" src={prathaa_sc} alt="sizechart" />
                                </span>
                            </div>
                        </div>
                    </>
                ) : (
                    <button className='sizechartbtn' onClick={handleOpenModal}>{msg.text}</button>
                )
            }
        </div>
    )
}

export default SizeChart