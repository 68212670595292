import React, { useState, useEffect, useRef } from 'react';
import './css/ChatBox.css';
// import submitSvg from '../assets/submit.svg';
import submitSvg from '../assets/send.svg';
import skip from '../assets/skip.png';
import ProductsScrollView from './ProductsScrollView';
import Feedback from './Feedback';
import { BASE_URL } from "../lib/common";
import Skeleton from '@mui/material/Skeleton';
import Box from '@mui/material/Box';
import SizeChart from './SizeChart';

const ChatBox = ({ storeName, messages, onMessageSubmit, fetchProductsAfterAddFilter, products, loading, setPageNo, hasMorePages, contextWindow, firstTimeFocused, scrollContainerRef, handleFeedback, selectedSmiles, setSelectedSmiles, hoveredSmiles, setHoveredSmiles, feedbackTaken, setFeedbackTaken, flow, handleFeedbackQueAnswer }) => {

    const [message, setMessage] = useState('');
    const chatContainerRef = useRef(null);

    const [imageLoaded, setImageLoaded] = useState(false);

    const replies = [
        "Explore a personalized look board for perfect style vibes.",
        "Discover curated outfits tailored to your taste.",
        "Choose carefully curated outfits for your unique style.",
        "Inspire your wardrobe with a fashion-forward look board.",
        "Capture flair with fantastic outfit ideas on this custom look board.",
        "Embark on a confident style journey with handpicked outfits.",
        "Enhance your style with a curated collection for a lasting impression.",
        "Presenting a carefully curated look board aligning with your taste.",
        "Unlock style possibilities with this exclusive guided look board.",
        "Experience a visual wardrobe blending comfort and style."
    ];

    let styleQuestions = [
        "What's your preferred style: classic, casual, or trendy?",
        "Do you have a favorite color or color palette you like to wear?",
        "Are you more into accessories or keeping it simple?",
        "What's your go-to footwear for different occasions?",
        "How would you describe your fashion personality in three words?",
        "Are you more drawn to patterns or solid colors?",
        "Do you have a fashion icon or someone whose style you admire?",
        "Are you a fan of layering or do you prefer a minimalistic look?",
        "Do you tend to follow fashion trends or stick to timeless pieces?",
        "What's your favorite season for fashion and why?"
    ];

    // console.log(styleQuestions);


    const pickRandomSentence = () => {
        const randomIndex = Math.floor(Math.random() * replies.length);
        return replies[randomIndex];
    };

    const pickRandomQuestion = () => {
        const randomIndex = Math.floor(Math.random() * styleQuestions.length);
        return styleQuestions[randomIndex];
    };


    useEffect(() => {
        if (chatContainerRef.current) {
            setTimeout(() => {
                const chatContainer = chatContainerRef.current;
                chatContainerRef.current.scrollTo({
                    top: chatContainer.scrollHeight,
                    behavior: 'smooth',
                });
            }, 500);
        }
    }, [messages]);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (message.trim() === '') return;

        const tip = pickRandomSentence();
        const question = pickRandomQuestion();
        onMessageSubmit(message, tip, question);

        setMessage('');
    };

    return (
        <div className='chatBoxCont'>
            <div ref={chatContainerRef} className="messageCont">
                {messages.map((msg, index) => (
                    <div key={index}>
                        {/* <span className={msg.user !== 'Vida' ? 'tag userTag' : 'tag botTag'} >{msg.user}</span> */}
                        {(() => {
                            switch (msg.type) {
                                case 'text':
                                case 'response':
                                case 'suggestion':
                                    return (
                                        <div style={{ whiteSpace: 'pre-line' }} className={msg.user !== 'Vida' ? 'msgDiv userMsgDiv' : 'msgDiv botMsgDiv'}>
                                            {msg.text}
                                        </div>
                                    );
                                case 'question':
                                    return (
                                        <div>
                                            {
                                                (msg.queObj) && (
                                                    <div style={{ whiteSpace: 'pre-line' }} className={msg.user !== 'Vida' ? 'msgDiv userMsgDiv' : 'msgDiv botMsgDiv'}>
                                                        {msg?.queObj?.question}
                                                    </div>
                                                )
                                            }

                                            {/* {index === messages.length - 1 && ( */}
                                            <>
                                                {
                                                    msg.queObj && (
                                                        (msg.queObj.option_images) ? (
                                                            // <div className='optionsCont2'>
                                                            //     {msg.queObj.options.map((option, idx) => (
                                                            //         <div style={(index !== messages.length - 1) ? { pointerEvents: "none" } : {}} className="optionDiv" onClick={() => { fetchProductsAfterAddFilter(msg.queObj, msg.queObj.userPref, msg.queObj.keys[idx], option) }} >
                                                            //             <img src={msg.queObj.option_images[idx]} alt="" className='optionImg' />
                                                            //             <span key={idx} style={{ display: "block" }} >{option}</span>
                                                            //         </div>
                                                            //     ))}
                                                            // </div>

                                                            <div div className={`optionsScrollView snaps-inline`} >
                                                                {
                                                                    msg.queObj.options.map((option, idx) => (
                                                                        <div className='optionCardCont' style={(index !== messages.length - 1) ? { pointerEvents: "none" } : {}} onClick={() => { fetchProductsAfterAddFilter(msg.queObj, msg.queObj.userPref, msg.queObj.keys[idx], option) }}>
                                                                            <Box>
                                                                                {!imageLoaded && (
                                                                                    <Skeleton variant="rounded"
                                                                                        sx={{
                                                                                            width: '100%',
                                                                                            height: 'auto',
                                                                                            aspectRatio: '3 / 4', // This sets the aspect ratio
                                                                                        }}
                                                                                    />
                                                                                )}
                                                                                <img src={`${BASE_URL}${msg.queObj.option_images[idx]}`} alt="optionImg" draggable={false} onLoad={() => setImageLoaded(true)} style={{ display: imageLoaded ? 'block' : 'none', width: '100%' }} />
                                                                            </Box>
                                                                            <div className='option-details'>
                                                                                <div className='option-title'>
                                                                                    {option}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    ))
                                                                }
                                                            </div >
                                                        ) : (
                                                            <div className='optionsCont'>
                                                                {msg.queObj.options.map((option, idx) => (
                                                                    <span key={idx} style={(index !== messages.length - 1) ? { pointerEvents: "none" } : {}} onClick={() => { fetchProductsAfterAddFilter(msg.queObj, msg.queObj.userPref, msg.queObj.keys[idx], option) }}>{option}</span>
                                                                ))}
                                                            </div>
                                                        )
                                                    )
                                                }

                                                {msg.skip && (
                                                    <div style={{ marginBottom: "8px", marginLeft: "0px", cursor: "pointer" }}>
                                                        <img src={skip} alt="skip" style={(index !== messages.length - 1) ? { width: "2.3rem", pointerEvents: "none", userSelect: "none" } : { width: "2.3rem", cursor: "pointer", userSelect: "none" }} onClick={() => { fetchProductsAfterAddFilter(msg.queObj, msg.queObj.userPref, "skipped", "Skipped") }} />
                                                    </div>
                                                )}
                                                {msg.KYB && (
                                                    <div style={(index !== messages.length - 1) ? { marginBottom: "10px", pointerEvents: "none", userSelect: "none" } : { marginBottom: "10px", cursor: "pointer", userSelect: "none" }} onClick={() => { onMessageSubmit("Know your Body", "", "") }}>
                                                        <span className='newFetureBtn'>
                                                            Know your Body
                                                        </span>
                                                    </div>
                                                )}
                                            </>
                                            {/* )} */}
                                        </div>
                                    );
                                case 'feedbackQue':
                                    return (
                                        <div>
                                            {
                                                (msg.queObj) && (
                                                    <div style={{ whiteSpace: 'pre-line' }} className={msg.user !== 'Vida' ? 'msgDiv userMsgDiv' : 'msgDiv botMsgDiv'}>
                                                        {msg?.queObj?.question}
                                                    </div>
                                                )
                                            }
                                            <>
                                                {
                                                    msg.queObj && (
                                                        <div className='optionsCont'>
                                                            {msg.queObj.options.map((option, idx) => (
                                                                <span key={idx} style={(index !== messages.length - 1) ? { pointerEvents: "none" } : {}} onClick={() => { handleFeedbackQueAnswer(msg.queObj, msg.queObj.userPref, msg.queObj.keys[idx], option) }}>{option}</span>
                                                            ))}
                                                        </div>
                                                    )
                                                }
                                            </>
                                        </div>
                                    );
                                case 'productList':
                                    return (
                                        // <div style={{ marginBottom: "10px" }}>
                                        <div style={{ marginBottom: "0px" }}>
                                            <ProductsScrollView storeName={storeName} loading={loading} products={products} setPageNo={setPageNo} hasMorePages={hasMorePages} contextWindow={contextWindow} fetchProductsAfterAddFilter={fetchProductsAfterAddFilter} firstTimeFocused={firstTimeFocused} scrollContainerRef={scrollContainerRef} flow={flow} />
                                        </div>
                                    );
                                case 'feedback':
                                    return (
                                        <>
                                            <div style={{ whiteSpace: 'pre-line' }} className={msg.user !== 'Vida' ? 'msgDiv userMsgDiv' : 'msgDiv botMsgDiv'}>
                                                {msg.text}
                                            </div>
                                            <div className='optionsCont' style={(index !== messages.length - 1) ? { pointerEvents: "none" } : {}}>
                                                <Feedback handleFeedback={handleFeedback} selectedSmiles={selectedSmiles} setSelectedSmiles={setSelectedSmiles}
                                                    hoveredSmiles={hoveredSmiles} setHoveredSmiles={setHoveredSmiles}
                                                    feedbackTaken={feedbackTaken} setFeedbackTaken={setFeedbackTaken} />
                                            </div>
                                        </>
                                    );
                                case 'sizechart':
                                    return (
                                        <SizeChart msg={msg}/>
                                    );
                                default:
                                    return null;
                            }
                        })()}
                    </div>
                ))}
            </div>
            <form onSubmit={handleSubmit} className='formInput'>
                <span>Powered by Style by Vida</span>
                <input
                    type="text"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    placeholder={storeName== "prathaa" ? "Ask Sree": "Ask Vida"}
                    className='inputCont'
                // disabled
                />
                <button type="submit" className='button' >
                    <img src={submitSvg} alt="SEND" style={{ height: "30px" }} />
                </button>
            </form>
        </div>
    );
};

export default ChatBox;
