import React, { useState, useRef, useEffect } from 'react';
import "./css/ChatBotMC.css";
import ChatBox from './ChatBox';
import { BASE_URL, getSessionId } from "../lib/common";
import logo from "../assets/vidaLogo1.png";
import vida from "../assets/vida.png";
import resetSvg from "../assets/reset.svg";
import { UXLog, getUserId, getMsgSeqId } from '../lib/logger';
// import { uxStoreName } from './local';
import { useLocation } from 'react-router-dom';

const ChatBotMC_2 = () => {
    const flow = "d2";
    const { search } = useLocation();
    const queryParams = new URLSearchParams(search);
    const storeName = queryParams.get('storeName') || 'kyb';
    const uxStoreName = queryParams.get('storeName') || 'kyb';

    const firstTime = useRef(true);
    const [feedbackTaken, setFeedbackTaken] = useState(false);
    const [selectedSmiles, setSelectedSmiles] = useState(0);
    const [hoveredSmiles, setHoveredSmiles] = useState(0);

    const [query, setQuery] = useState('');
    const [pageNo, setPageNo] = useState(1);
    const [hasMorePages, setHasMorePages] = useState(true);
    const [loading, setLoading] = useState(false);

    const scrollContainerRef = useRef(null);

    const [products, setProducts] = useState(null);
    const [contextWindow, setContextWindow] = useState({});

    const [messages, setMessages] = useState([]);

    const defaultQue = {
        type: "question",
        // queObj: {
        //     type: "default",
        //     userPref: "apparel_type",
        //     question: "What are you looking for today?",
        //     options: [
        //         "Tops",
        //         "T-Shirts",
        //         "Shirts",
        //         "Outerwear",
        //         "Pants",
        //         "Skirts",
        //         "Leggings"
        //     ],
        //     keys: [
        //         "tops",
        //         "t-shirts",
        //         "shirts",
        //         "outerwear",
        //         "pants",
        //         "skirts",
        //         "leggings"
        //     ]
        // },
        user: 'Vida',
        skip: false,
        KYB: true,
    };

    const handleMessageSubmit = (message, tip, question) => {
        const curMsgobj = { type: "text", text: message, user: 'Shopper' };
        setMessages((prevMessages) => [
            ...prevMessages,
            curMsgobj,
        ]);
        // UXLog(uxStoreName, curMsgobj);
        UXLog(uxStoreName, { type: "askVida", text: message, user: 'Shopper' });
        fetchProducts(message == "Know your Body" ? "know-your-body" : message, 1);
    };

    const handleFeedback = (feedbackSmile) => {
        // setMessages((prevMessages) => [
        //     ...prevMessages,
        //     { type: "text", text: feedbackSmile, user: 'Shopper' },
        // ]);

        setMessages((prevMessages) => [
            ...prevMessages,
            { type: "text", text: "Thank You. See you Soon.", user: 'Vida' },
        ]);

        const curMsgobj = { type: "feedback", text: feedbackSmile.toString(), user: "Shopper" };
        UXLog(uxStoreName, curMsgobj);
        console.log("FEEDBACK:", feedbackSmile);
    };

    const removeProducts = async () => {
        setProducts([]);
    }

    const setTextResponse = (message, numOfProdcuts) => {
        let typ = (numOfProdcuts > 1) ? "response" : "suggestion";
        const curMsgobj = { type: typ, text: message, user: 'Vida' };
        if (typ === "response") {
            setMessages((prevMessages) => {
                const filteredMessages = prevMessages.filter((msg) => (msg.type !== "response"));
                const newMessages = [
                    ...filteredMessages,
                    curMsgobj
                ];

                return newMessages;
            });
        }
        else {
            setMessages((prevMessages) => [
                ...prevMessages,
                curMsgobj
            ]);
        }
        UXLog(uxStoreName, curMsgobj);
    }

    const checkQuestionAndDisplayProducts = (productList) => {
        let queFound = false;
        for (const obj of productList) {
            if (obj.type == "default" && obj.userPref != "nothing") {
                const curMsgobj = { type: "question", queObj: obj, user: 'Vida', skip: false }
                setMessages((prevMessages) => [
                    ...prevMessages,
                    curMsgobj,
                ]);
                queFound = true;
                UXLog(uxStoreName, curMsgobj);
                break;
            }
        };
        if (!queFound) {
            if (productList.length >= 1) {

                const curMsgobj = { type: "productList", numOfProduct: productList.length, user: "Vida" }
                UXLog(uxStoreName, curMsgobj);

                const feedbackobj = { type: "feedback", text: "How happy are you with your Styling experience?", user: "Vida" };

                setMessages((prevMessages) => {
                    const filteredMessages = prevMessages.filter((msg) => msg.type !== "productList");

                    const newMessages = [
                        ...filteredMessages,
                        curMsgobj,
                        ...(feedbackTaken
                            ? []
                            : [feedbackobj])
                    ];

                    // setFeedbackTaken(true);

                    return newMessages;
                });

                if (!feedbackTaken) UXLog(uxStoreName, feedbackobj);
            }

        }
    }

    const fetchProducts = async (userQuery, pageNo) => {
        const user_id = await getUserId(uxStoreName);
        const sbv_sid = getSessionId();
        const MsgSeqId = getMsgSeqId(uxStoreName);

        setLoading(true);
        // const url = `http://64.227.152.133:5000/sbv/ml/imageSearch?store_name=${storeName}&user_name=Stylist&userPrompt=${userQuery}&page_no=${pageNo}&devName=shiv`;
        const url = `${BASE_URL}/sbv/aistylist/searchQuery?storeName=${storeName}&user_name=Stylist&userPrompt=${userQuery}&page_no=${pageNo}&user_id=${user_id}&sbv_sid=${sbv_sid}&MsgSeqId=${MsgSeqId}&is_dual=false&client_type=chatbot&flow=${flow}&devName=shiv`;

        // if (pageNo == 1) await removeProducts();

        await fetch(url, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then((response) => response.json())
            .then(async (data) => {
                if (pageNo == 1) {
                    if (data.productList.length > 1) {
                        await removeProducts();
                        setHasMorePages(data.productList.length >= 20 ? true : false);
                        setProducts(data.productList);
                    }
                }
                else {
                    setHasMorePages(data.productList.length >= 20 ? true : false);
                    setProducts([...products, ...data.productList]);
                }

                if (pageNo == 1) {
                    if (data.text_response) setTextResponse(data.text_response, data.productList.length);
                    checkQuestionAndDisplayProducts(data.productList);
                    // UXLog(uxStoreName, 'AskVida', userQuery, {"contextWindow":data.contextWindow, "productList":data.productList, "text_response":(data.text_response) ? data.text_response: ""});
                    // UXLog(uxStoreName, 'AskVida', userQuery, {"contextWindow":data.contextWindow, "text_response":(data.text_response) ? data.text_response: "", "number_of_products" : data.productList.length});
                }

                setContextWindow(data.contextWindow);
                setLoading(false);
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
                setLoading(false);
            });
    };

    const fetchProductsAfterAddFilter = async (queObj, filterType, filterName, filter) => {
        const curMsgobj = { type: "text", text: filter, user: 'Shopper' };
        setMessages((prevMessages) => [
            ...prevMessages,
            curMsgobj,
        ]);

        // UXLog(uxStoreName, curMsgobj);
        UXLog(uxStoreName, { type: "filter", text: filter, user: 'Shopper' });

        const user_id = await getUserId(uxStoreName);
        const sbv_sid = getSessionId();
        const MsgSeqId = getMsgSeqId(uxStoreName);

        setLoading(true);
        const url = `${BASE_URL}/sbv/aistylist/addFilter?storeName=${storeName}&user_name=Stylist&filterType=${filterType}&filterName=${filterName}&user_id=${user_id}&sbv_sid=${sbv_sid}&MsgSeqId=${MsgSeqId}&is_dual=false&client_type=chatbot&flow=${flow}&devName=shiv`;

        await removeProducts();

        await fetch(url, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then((response) => response.json())
            .then((data) => {
                setHasMorePages(data.productList.length >= 20 ? true : false);
                setProducts(data.productList);

                if (data.text_response) setTextResponse(data.text_response, data.productList.length);
                checkQuestionAndDisplayProducts(data.productList);

                setContextWindow(data.contextWindow);
                if (scrollContainerRef.current) {
                    const tempScrollLeft = scrollContainerRef.current.scrollLeft;
                    setTimeout(() => {
                        scrollContainerRef.current.scrollTo({
                            left: 0,
                            behavior: 'auto',
                        });
                    }, 0);
                }
                // UXLog(uxStoreName, 'AddFilter', filterType, filterName, {"contextWindow":data.contextWindow, "productList":data.productList, "text_response":(data.text_response) ? data.text_response: ""});
                // UXLog(uxStoreName, 'AddFilter', queObj, filterName, {"contextWindow":data.contextWindow, "text_response":(data.text_response) ? data.text_response: "", "number_of_products" : data.productList.length});
                setLoading(false);
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
                setLoading(false);
            });
    };

    useEffect(() => {
        if (firstTime.current) {
            firstTime.current = false;
            setTimeout(() => {
                const curMsgobj = { type: "text", text: "HI! I’m Vida, your AI Stylist.\nReady to know patterns and fits that work for your body type.", user: 'Vida' };
                setMessages((prevMessages) => [
                    ...prevMessages,
                    curMsgobj
                ]);
                UXLog(uxStoreName, curMsgobj);
            }, 500);

            setTimeout(() => {
                fetchProducts("know-your-body", 1);
            }, 2000);

            // setTimeout(() => {
            //     setMessages((prevMessages) => [
            //         ...prevMessages,
            //         defaultQue
            //     ]);
            //     UXLog(uxStoreName, defaultQue);
            // }, 2000);
        }
    }, [firstTime]);


    useEffect(() => {
        console.log("pageNo", pageNo);
        // if (query && query.trim() !== "") {
        // fetchProducts(query, pageNo);
        // }
        if (pageNo != 1) {
            fetchProducts(query, pageNo);
        }
    }, [pageNo])

    const resetSession = () => {
        // alert("RESETING");
        setQuery('');
        setPageNo(1);
        setHasMorePages(true);
        setLoading(false);

        UXLog(uxStoreName, { type: "text", text: "Reset", user: 'Shopper' });
        UXLog(uxStoreName, { type: "text", text: "Resetting...", user: 'Vida' });
        sessionStorage.removeItem('sbv_sid');

        const curMsgobj = { type: "text", text: "Let's start over again...", user: 'Vida' };

        setMessages((prevMessages) => {
            const filteredMessages = prevMessages.filter((msg) => (msg.type !== "productList"));
            const newMessages = [
                ...filteredMessages,
                curMsgobj
            ];
            return newMessages;
        });

        UXLog(uxStoreName, curMsgobj);

        setTimeout(() => {
            fetchProducts("know-your-body", 1);
        }, 500);

        // setTimeout(() => {
        //     setMessages((prevMessages) => [
        //         ...prevMessages,
        //         defaultQue
        //     ]);
        //     UXLog(uxStoreName, defaultQue);
        // }, 500);

        // window.location.reload();
    };

    return (
        <div className='chatBotMC'>
            <div className='headingDiv'>
                <div>
                    <img style={{ width: "36px", height: "30px", borderRadius: "50%" }} src={vida} alt="logo" draggable={false} />
                </div>
                <div className="logo">
                    <img src={logo} alt="logo" draggable={false} />
                </div>
                {/* <div>
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        AI STYLIST
                    </div>
                    <div style={{ lineHeight: "unset", textAlign: "left" }}>
                        online
                    </div>
                </div> */}
                <div className="resetIconDiv" onClick={resetSession}>
                    <img src={resetSvg} alt="reset" draggable={false} />
                </div>
            </div>
            <ChatBox storeName={storeName} loading={loading} messages={messages} products={products}
                setPageNo={setPageNo} hasMorePages={hasMorePages} contextWindow={contextWindow} firstTimeFocused={false}
                onMessageSubmit={handleMessageSubmit} fetchProductsAfterAddFilter={fetchProductsAfterAddFilter} handleFeedback={handleFeedback}
                scrollContainerRef={scrollContainerRef}
                selectedSmiles={selectedSmiles} setSelectedSmiles={setSelectedSmiles}
                hoveredSmiles={hoveredSmiles} setHoveredSmiles={setHoveredSmiles}
                feedbackTaken={feedbackTaken} setFeedbackTaken={setFeedbackTaken}
                flow={flow}
            />
        </div>
    )
}

export default ChatBotMC_2;