import React, { useState } from 'react';
import { BASE_URL } from "../lib/common";
// import dark_smile_svg from '../assets/DarkSmile.svg';
// import light_smile_svg from '../assets/LightSmile.svg';
import dark_smile_svg from '../assets/DarkStar.svg';
import light_smile_svg from '../assets/LightStar.svg';
import './css/Feedback.css';

const Feedback = ({ handleFeedback, selectedSmiles, setSelectedSmiles, hoveredSmiles, setHoveredSmiles, feedbackTaken, setFeedbackTaken }) => {


    const handleSmileClick = (index) => {
        setSelectedSmiles(index + 1);
        handleFeedback(index + 1);
        setFeedbackTaken(true);
    };

    const handleSmileHover = (index) => {
        setHoveredSmiles(index + 1);
    };

    const handleSmileLeave = () => {
        setHoveredSmiles(0);
    };

    return (
        <div className={`smileCont ${feedbackTaken ? 'disabled' : ''}`}>
            {Array.from({ length: 5 }, (_, index) => (
                <img
                    key={index}
                    className="smileSvg"
                    src={index < selectedSmiles || index < hoveredSmiles ? dark_smile_svg : light_smile_svg}
                    alt="smile"
                    onClick={() => !feedbackTaken && handleSmileClick(index)}
                    onMouseEnter={() => !feedbackTaken && handleSmileHover(index)}
                    onMouseLeave={handleSmileLeave}
                    style={{ cursor: feedbackTaken ? 'not-allowed' : 'pointer', fill:'#ff0000' }}
                />
                // <div
                //     key={index}
                //     className={`smileSvg ${index < selectedSmiles || index < hoveredSmiles ? "s1" : "s2"}`}
                //     alt="smile"
                //     onClick={() => !feedbackTaken && handleSmileClick(index)}
                //     onMouseEnter={() => !feedbackTaken && handleSmileHover(index)}
                //     onMouseLeave={handleSmileLeave}
                //     style={{ cursor: feedbackTaken ? 'not-allowed' : 'pointer' }}
                // />
            ))}
        </div>
    )
}

export default Feedback;