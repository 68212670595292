import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import MainContainer1 from './component1/MainContainer';
import MainContainer2 from './component2/MainContainer';
import MainContainer3 from './component3/ChatBotMC';
import MainContainer4 from './component3/ChatBotMC_2';
import MainContainer5 from './component3/ChatBotMC_3';

function App() {
  return (
      <div className="App">
        <Router>
        <Routes>
          <Route exact path="/" element={<MainContainer1/>} />
          <Route exact path="/v2" element={<MainContainer2/>}/>
          <Route exact path="/v3" element={<MainContainer3/>}/>
          <Route exact path="/v4" element={<MainContainer4/>}/>
          <Route exact path="/v5" element={<MainContainer5/>}/>
        </Routes>
        </Router>
      </div>
  );
}

export default App;
