import React from 'react';
import styles from './css/ProductCard.module.css';
import { BASE_URL } from '../lib/common';
import Tooltip from './Tooltip';

// const ProductCard = ({ id, title, imageSrc, price, description, onClick}) => {
const ProductCard = ({ storeName, product_handle, title, imgUrl, body_pref_tags, vendor_name, price, onClick }) => {

    const brandLogos = {
        'fablestreet': "https://www.fablestreet.com/favicon.ico",
        'thelabellife': "https://labellifeassets.tectonic.so/images/original/cce0315262d7f7341c7bcef3a0f2623a.png",
        'cavaathleisure': "https://cavaathleisure.com/cdn/shop/files/logo-01_32x32.png?v=1710322846"
    };
    const bLogo = brandLogos[vendor_name];

    const { positive_tags, negative_tags } = body_pref_tags;

    const truncateTitle = (title, maxChars) => {
        if (title.length <= maxChars) {
            return title;
        } else {
            const truncatedTitle = title.substring(0, maxChars);
            const lastSpaceIndex = truncatedTitle.lastIndexOf(' ');

            if (truncatedTitle[lastSpaceIndex - 1] === '-') {
                return truncatedTitle.substring(0, lastSpaceIndex - 1);
            }

            return truncatedTitle.substring(0, lastSpaceIndex);
        }
    };

    return (
        <div className={styles['productCardCont']} onClick={onClick}>
            {/* <div style={{width:"100%", height:"85%", border:"2px solid green", overflow:"hidden"}}> */}
            <div>
                {/* <span className='priceCont'>₹{price}</span> */}
                {/* <img src={imageSrc} alt="productImg" draggable={false}/> */}
                {/* <span className='priceCont'>₹{99}</span> */}
                <img src={`${BASE_URL}/getProdImage?store_name=${storeName}&dress_id=${product_handle}&image_name=${imgUrl}`} alt="productImg" draggable={false} />
            </div>
            <div className={styles["product-details"]}>
                {/* <h2 className="product-title">{truncateTitle(title, 30)}</h2> */}
                <div className={styles["product-title"]}>

                {bLogo && (
                        <div>
                            <Tooltip text={vendor_name}>
                                <img src={bLogo} alt="" style={{ width: "15px", height: "15px", marginRight: "5px" }} />
                            </Tooltip>
                        </div>
                    )
                    }

                    {positive_tags && positive_tags.length > 0 ? (
                        positive_tags.map((tag, index) => (
                            // <span key={index} style={{ backgroundColor:"#078c00b8" }} className='nudge'>
                            <span key={index} style={{ border: "1px solid green" }} className={styles['nudge']}>
                                {tag}
                            </span>
                        ))
                    ) : null}

                    {negative_tags && negative_tags.length > 0 ? (
                        negative_tags.map((tag, index) => (
                            // <span key={index} style={{ backgroundColor:"#d20000b8" }} className='nudge'>
                            <span key={index} style={{ border: "1px solid red" }} className={styles['nudge']}>
                                {tag}
                            </span>
                        ))
                    ) : null}

                    {((positive_tags && negative_tags && positive_tags.length === 0 && negative_tags.length === 0) || body_pref_tags.length === 0) && (
                        <span className={styles['nudge']}>
                            {truncateTitle(title, 30)}
                        </span>
                    )}

                </div>
            </div>
        </div>
    )
}

export default ProductCard;