import React from 'react';
import { Spinner } from 'react-bootstrap';

const Loader = ({storeName}) => {
    return (
        <div style={{height:"100%", display:"flex", justifyContent:"center", alignItems:"center"}}>
            <Spinner animation="grow" variant={ storeName ? "danger" : "light"} />
            {/* <Spinner animation="border" variant="secondary" /> */}
        </div>
    )
}

export default Loader;