export const BASE_URL = process.env.REACT_APP_BACKEND_BASE_URL


export function getCurrDateTime() {
    const currentDate = new Date();
    const day = String(currentDate.getDate()).padStart(2, "0");
    const month = currentDate.toLocaleString("en-US", { month: "short" });
    const year = currentDate.getFullYear();
    const hours = String(currentDate.getHours()).padStart(2, "0");
    const minutes = String(currentDate.getMinutes()).padStart(2, "0");
    const seconds = String(currentDate.getSeconds()).padStart(2, "0");
    const milliseconds = String(currentDate.getMilliseconds()).padStart(3, "0");
    const dateTime = `${day}-${month}-${year}-${hours}-${minutes}-${seconds}-${milliseconds}`;
    return dateTime;
}

function generateRandomNumber(max) {
    let randomNumber = Math.floor(Math.random() * max) + 1;
    return randomNumber.toString();
}

export const getSessionId = () => {
    let sessionId = null;
    if (sessionStorage.getItem(`sbv_sid`) === null) {
      let dateTime = getCurrDateTime();
      let randomNumber = generateRandomNumber(1000);
      sessionId = dateTime + "-" + randomNumber;
      sessionStorage.setItem(`sbv_sid`, sessionId);
      console.log("Setting SessionId:", sessionId);
    } else {
      let retrievedSessionId = sessionStorage.getItem(`sbv_sid`);
      sessionId = retrievedSessionId;
      console.log("RetrievedSessionId:", retrievedSessionId);
    }
    return sessionId;
};