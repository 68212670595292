import React, { useState, useEffect } from 'react';
import styles from './css/ProductDetailCard.module.css';
import Loader from './Loader';
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { BASE_URL } from '../lib/common.js';

// const ProductDetailCard = ({id, title, imageSrc, price, description}) => {
// const ProductDetailCard = ({ product_handle: id, title, imgUrl: imageSrc, price, description}) => {
const ProductDetailCard = ({ storeName, product_handle }) => {

    const brandLogos = {
        'fablestreet': "https://www.fablestreet.com/favicon.ico",
        'thelabellife': "https://labellifeassets.tectonic.so/images/original/cce0315262d7f7341c7bcef3a0f2623a.png",
        'cavaathleisure': "https://cavaathleisure.com/cdn/shop/files/logo-01_32x32.png?v=1710322846"
    };
    const brandSiteLink = {
        'fablestreet': "https://www.fablestreet.com/products/",
        'thelabellife': "https://store.thelabellife.com/products/",
        'cavaathleisure': "https://cavaathleisure.com/products/"
    };

    const [firstTime, setFirstTime] = useState(1);
    const [loading, setLoading] = useState(false);
    const [productInfo, setProductInfo] = useState(null);

    const [selectedSize, setSelectedSize] = useState(null);
    const [sizeOptionIndex, setSizeOptionIndex] = useState(0);
    const [selectedVariant, setSelectedVariant] = useState(null);

    const [isCollapsed, setIsCollapsed] = useState(true);

    const fetchProductInfo = () => {
        setLoading(true);
        // const url = `/sbv/aistylist/getProductInfo?storeName=${storeName}&product_handle=${product_handle}`;
        const url = `${BASE_URL}/sbv/aistylist/getProductInfo?storeName=${storeName}&product_handle=${product_handle}`;

        fetch(url, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then((response) => response.json())
            .then((data) => {
                setProductInfo(data);
                setLoading(false);
            })
            .catch((error) => {
                console.error('Error fetching product Info:', error);
                setLoading(false);
            });
    };

    useEffect(() => {
        if (firstTime) {
            fetchProductInfo();
            setFirstTime(0);
        }
    }, [])

    useEffect(() => {
        const sizes = productInfo?.options?.find(option => option.name.trim().toLowerCase() === "size");
        const variantIndex = productInfo?.options?.findIndex(option => option.name.trim().toLowerCase() === "size");
        if (variantIndex) setSizeOptionIndex(variantIndex);
        if (sizes && sizes.values.length > 0) {
            setSelectedSize(sizes.values[0]);
        }
    }, [productInfo]);

    useEffect(() => {
        const variantForSize = productInfo?.variants?.find(variant => variant[`option${sizeOptionIndex + 1}`] === selectedSize);
        if (variantForSize) {
            console.log("variantForSize.price=", variantForSize.price);
            setSelectedVariant(variantForSize);
        }
    }, [selectedSize, productInfo?.variants, productInfo?.options]);

    const settings = {
        dots: true,
        lazyLoad: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
    };

    const toggleCollapse = () => {
        setIsCollapsed(!isCollapsed);
    };

    return (
        <>
            {
                loading ? (
                    <Loader />
                ) : (
                    productInfo && (
                        <div className={styles['productDetailCardCont']}>
                            <div className={styles['imgBox']}>
                                {/* <img src={imageSrc} alt="productImg" draggable={false}/> */}
                                {/* <img src={`http://d1.engagevida.com:7070/getProdImage?store_name=${storeName}&dress_id=${id}&image_name=${imageSrc}`} alt="productImg" draggable={false} /> */}
                                <Slider {...settings}>
                                    {
                                        productInfo.images.map((image, index) => (
                                            <div key={index}>
                                                <img src={image.src} alt="productImg" draggable={false} />
                                            </div>
                                        ))
                                    }
                                </Slider>
                            </div>
                            <div className={styles["product-details"]}>
                                {brandLogos[productInfo.vendor.toLowerCase().replace(/\s/g, '')] && (
                                    <div>
                                        <img src={brandLogos[productInfo.vendor.toLowerCase().replace(/\s/g, '')]} alt="" style={{ width: "50px", height: "50px" }} />
                                        <span style={{ marginLeft: '5px', textTransform: "capitalize" }}>{productInfo.vendor.toLowerCase().replace(/\s/g, '')}</span>
                                    </div>
                                )}
                                <div style={{ display: "flex", flexDirection: "column", margin: '8px' }}>
                                    {/* <h2 className="product-title">{title}</h2> */}
                                    {/* <p className="product-description">{description}</p> */}
                                    {/* <p className="product-price">₹ {price}</p> */}

                                    <h2 className={styles["product-title"]}>{productInfo.title}</h2>
                                    <h2 className={styles["product-title"]}>{productInfo.handle}</h2>
                                    {/* <p className="product-description">{productInfo.body_html}</p> */}
                                    {/* <span className="product-description" dangerouslySetInnerHTML={{ __html: productInfo.body_html }} /> */}
                                    <div className={styles["product-description"]} dangerouslySetInnerHTML={{ __html: isCollapsed ? `${productInfo.body_html.substring(0, 200)}...` : productInfo.body_html }} />
                                    <span onClick={toggleCollapse} style={{ cursor: 'pointer', color: 'black', marginBottom: '5px' }}>
                                        {isCollapsed ? 'See more' : 'See less'}
                                    </span>
                                    {
                                        productInfo.options.map((option, idx) => (
                                            (option.name.trim().toLowerCase() === "size") && (
                                                <div key={idx}>
                                                    <h5 className={styles['variantTitle']}>Size</h5>
                                                    <ul className={styles["listItemCont"]}>
                                                        {
                                                            option.values.map((size) => (
                                                                <li
                                                                    key={size}
                                                                    className={`${styles.listItem} ${size === selectedSize ? styles['selectedSize'] : ''}`}
                                                                    onClick={() => setSelectedSize(size)}
                                                                >
                                                                    {size}
                                                                </li>
                                                            ))
                                                        }
                                                    </ul>
                                                </div>
                                            )
                                        ))
                                    }
                                    {selectedVariant && <p className={styles["product-price"]}>₹ {selectedVariant.price}</p>}
                                </div>
                                <div style={{ display: "flex", flexDirection: "column" }}>
                                    <a href={`${brandSiteLink[productInfo.vendor.toLowerCase().replace(/\s/g, '')]}${productInfo.handle}`} target='_blank'>
                                        <button className={`${styles.btn} ${styles.btn1}`}>Shop Now</button>
                                    </a>
                                    <button className={`${styles.btn} ${styles.btn1}`} style={{ backgroundColor: "rgba(239, 239, 239, 0.3)", color: "rgb(16,16,16,0.6)", border: "1pz solid grey !important", cursor: "not-allowed" }} disabled>Add to cart</button>
                                    {/* <button className='btn btn2'>But It Now</button> */}
                                </div>
                            </div>
                        </div>
                    )
                )
            }
        </>
    )
}

export default ProductDetailCard;