import React, { useState, useEffect, useRef } from 'react';
import SearchIt from './SearchIt';
import ProductsScrollView from './ProductsScrollView';
import Loader from './Loader';
import styles from './css/MainContainer.module.css';
import bgVedio from '../assets/bg.mp4';
import { BASE_URL, getSessionId } from '../lib/common.js';
import vidaGif from '../assets/vida.gif';
import {getUserId, getMsgSeqId} from '../lib/logger';
import { uxStoreName } from './local';
import { useLocation } from 'react-router-dom';

const MainContainer = () => {
    const { search } = useLocation();
    const queryParams = new URLSearchParams(search);
    const storeName = queryParams.get('storeName') || 'aistylist';

    const [query, setQuery] = useState('');
    const [pageNo, setPageNo] = useState(1);
    const [hasMorePages, setHasMorePages] = useState(true);
    const [loading, setLoading] = useState(false);

    const scrollContainerRef = useRef(null);

    const [products, setProducts] = useState(null);
    const [contextWindow, setContextWindow] = useState({});
    const [filters, setFilters] = useState({});
    const [selectedFilters, setSelectedFilters] = useState([]);

    const [firstTimeFocused, setFirstTimeFocused] = useState(true);

    const removeProducts = async () => {
        setProducts([]);
    }

    const fetchProducts = async (userQuery, pageNo) => {
        const user_id = await getUserId(uxStoreName);
        const sbv_sid = getSessionId();
        const MsgSeqId = getMsgSeqId(uxStoreName);

        setLoading(true);
        // const url = `http://64.227.152.133:5000/sbv/ml/imageSearch?store_name=${storeName}&user_name=Stylist&userPrompt=${userQuery}&page_no=${pageNo}&devName=shiv`;
        const url = `${BASE_URL}/sbv/aistylist/searchQuery?storeName=${storeName}&user_name=Stylist&userPrompt=${userQuery}&page_no=${pageNo}&user_id=${user_id}&sbv_sid=${sbv_sid}&MsgSeqId=${MsgSeqId}&is_dual=false&client_type=promptbased&devName=shiv`;

        if(pageNo == 1) await removeProducts();

        await fetch(url, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then((response) => response.json())
            .then((data) => {
                setHasMorePages(data.productList.length >= 20 ? true : false);
                setLoading(false);
                console.log("data.productList: ", data.productList);
                if (pageNo == 1) setProducts(data.productList);
                else setProducts([...products, ...data.productList]);
                setContextWindow(data.contextWindow);
                if (data.filters && data.filters.filter) {
                    let filters = data.filters.filter;
                    setFilters(filters);
                }
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
                setLoading(false);
            });
    };

    const fetchProductsAfterCrossFilter = async (filterName) => {
        const user_id = await getUserId(uxStoreName);
        const sbv_sid = getSessionId();
        const MsgSeqId = getMsgSeqId(uxStoreName);
        setLoading(true);
        const url = `${BASE_URL}/sbv/aistylist/crossFilter?storeName=${storeName}&user_name=Stylist&filterName=${filterName}&user_id=${user_id}&sbv_sid=${sbv_sid}&MsgSeqId=${MsgSeqId}&is_dual=false&client_type=promptbased&devName=shiv`;

        await removeProducts();

        await fetch(url, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then((response) => response.json())
            .then((data) => {
                setHasMorePages(data.productList.length >= 20 ? true : false);
                setLoading(false);
                console.log("data.productList: ", data.productList);
                setProducts(data.productList);
                setContextWindow(data.contextWindow);
                if (data.filters && data.filters.filter) {
                    let filters = data.filters.filter;

                    // const filterList = Object.keys(filters).reduce((acc, key) => {
                    //     const values = filters[key].map(item => item);
                    //     return [...acc, ...values];
                    // }, []);
                    setFilters(filters);
                }

                if (scrollContainerRef.current) {
                    const tempScrollLeft = scrollContainerRef.current.scrollLeft;
                    setTimeout(() => {
                        scrollContainerRef.current.scrollTo({
                            left: 0,
                            behavior: 'auto',
                        });
                    }, 0);
                }
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
                setLoading(false);
            });
    };

    const fetchProductsAfterAddFilter = async (filterType, filterName) => {
        const user_id = await getUserId(uxStoreName);
        const sbv_sid = getSessionId();
        const MsgSeqId = getMsgSeqId(uxStoreName);

        setLoading(true);
        const url = `${BASE_URL}/sbv/aistylist/addFilter?storeName=${storeName}&user_name=Stylist&filterType=${filterType}&filterName=${filterName}&user_id=${user_id}&sbv_sid=${sbv_sid}&MsgSeqId=${MsgSeqId}&is_dual=false&client_type=promptbased&devName=shiv`;

        await removeProducts();

        await fetch(url, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then((response) => response.json())
            .then((data) => {
                setHasMorePages(data.productList.length >= 20 ? true : false);
                setLoading(false);
                console.log("data.productList: ", data.productList);
                setProducts(data.productList);
                setContextWindow(data.contextWindow);
                if (data.filters && data.filters.filter) {
                    let filters = data.filters.filter;

                    // const filterList = Object.keys(filters).reduce((acc, key) => {
                    //     const values = filters[key].map(item => item);
                    //     return [...acc, ...values];
                    // }, []);

                    setFilters(filters);
                }

                if (scrollContainerRef.current) {
                    const tempScrollLeft = scrollContainerRef.current.scrollLeft;
                    setTimeout(() => {
                        scrollContainerRef.current.scrollTo({
                            left: 0,
                            behavior: 'auto',
                        });
                    }, 0);
                }
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
                setLoading(false);
            });
    };

    useEffect(() => {
        setPageNo(1);
        setHasMorePages(true);
        if (query && query.trim() !== "") {
            fetchProducts(query, 1);
            if (scrollContainerRef.current) {
                const tempScrollLeft = scrollContainerRef.current.scrollLeft;
                setTimeout(() => {
                    scrollContainerRef.current.scrollTo({
                        left: 0,
                        behavior: 'auto',
                    });
                }, 0);
            }
        }
    }, [query])

    useEffect(() => {
        console.log("pageNo", pageNo);
        if (query && query.trim() !== "") {
            fetchProducts(query, pageNo);
        }
    }, [pageNo])

    useEffect(() => {
        if (contextWindow) {
            const filterList = Object.keys(contextWindow).reduce((acc, key) => {
                if (!key.startsWith("__") && !key.startsWith("state")) {
                    const values = contextWindow[key].map(item => item.key);
                    return [...acc, ...values];
                }
                return acc;
            }, []);
            setSelectedFilters(filterList);
        }
    }, [contextWindow]);

    return (
        <div className={styles.mainCont}>
            <SearchIt setQuery={setQuery} firstTimeFocused={firstTimeFocused} setFirstTimeFocused={setFirstTimeFocused} selectedFilters={selectedFilters} filters={filters} fetchProductsAfterCrossFilter={fetchProductsAfterCrossFilter} fetchProductsAfterAddFilter={fetchProductsAfterAddFilter} setProducts={setProducts} />
            {
                !firstTimeFocused &&
                <div className={styles.scrollCont}>
                    {(loading && (products == null)) && <Loader />}
                    <ProductsScrollView storeName={storeName} loading={loading} products={products} setPageNo={setPageNo} hasMorePages={hasMorePages} contextWindow={contextWindow} fetchProductsAfterAddFilter={fetchProductsAfterAddFilter} firstTimeFocused={firstTimeFocused} scrollContainerRef={scrollContainerRef} />
                </div>
            }
            {
                firstTimeFocused &&
                (<div className={styles.vidaGifCont}>
                    <img className={styles.vidaGif} src={vidaGif} alt="gif" />
                </div>)
            }
        </div>
    )
}

export default MainContainer;