import React, { useState } from 'react';
import styles from './css/QuestionCard.module.css';

const QuestionCard = ({ queObj, fetchProductsAfterAddFilter }) => {

    return (
        <div className={styles['questionCardCont']}>
            <div>
                <h2 className={styles["question"]}>{queObj.question}</h2>
            </div>
            <div className={styles['optionCont']}>
                {
                    queObj.options.map((option, idx) => (
                        <div className={styles['option-item']} key={option} onClick={() => { fetchProductsAfterAddFilter(queObj.userPref, queObj.keys[idx]) }} >{option}</div>
                    ))
                }
            </div>
        </div>
    )
}

export default QuestionCard;